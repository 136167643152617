import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import Shell from 'shell/Shell';
import { store } from 'store';

import RootRoutes from './RootRoutes';
import './styles/reset.scss';

export const App = () => {
  return (
    <Provider store={store}>
      <Router>
        <Shell>
          <RootRoutes />
        </Shell>
      </Router>
    </Provider>
  );
};
