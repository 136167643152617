import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router';

import { Button } from 'common/components/Button';
import { login, signUp } from 'store/slices/User/api';
import { selectIsLoggedIn } from 'store/slices/User/selectors';

import './styles.scss';

export const AuthPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(selectIsLoggedIn);
  if (isLoggedIn) {
    return <Redirect to="/" />;
  }

  return (
    <div className="auth-page">
      <form>
        <label htmlFor="email-input">
          <span>email</span>
          <input
            id="email-input"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </label>
        <label htmlFor="password-input">
          <span>password</span>
          <input
            id="password-input"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </label>
        <div className="buttons">
          <Button
            onClick={() => dispatch(login({ email, password }))}
            text="sign in"
          />
          <Button
            onClick={() => dispatch(signUp({ email, password }))}
            text="sign up"
          />
        </div>
      </form>
    </div>
  );
};
