import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import { Button } from 'common/components/Button';
import { selectIsFetchingUser } from 'store/slices/User/selectors';

import './styles.scss';

export const HomePage = () => {
  const fetching = useSelector(selectIsFetchingUser);
  const [query, setQuery] = useState('');
  const history = useHistory();

  if (fetching) {
    return <div>Loading...</div>;
  }

  return (
    <div className="home-page">
      <form>
        <input
          className="dictionary-search-input"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
        />
        <Button
          className="dictionary-search-submit"
          onClick={() => history.push(`/dict/${query}`)}
          text="Look up"
          isSubmit
        />
      </form>
      <Link className="card-button" to="/cards">
        Cards
      </Link>
    </div>
  );
};
