import { createAsyncThunk } from '@reduxjs/toolkit';

import { AsyncThunkApi } from 'store';
import { fetchApi } from 'store/fetchApi';

interface IncrementParams {
  entryId: number;
  senseIndex: number;
  userWordId?: string;
  sentence?: string;
}

export const increment = createAsyncThunk<void, IncrementParams, AsyncThunkApi>(
  'increment',
  async (params, thunkApi) => {
    return thunkApi.dispatch(
      fetchApi<void>('/entry/increment/', {
        method: 'POST',
        body: JSON.stringify(params),
        useAuth: true,
      }),
    );
  },
);
