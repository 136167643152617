import { Action, ThunkAction } from '@reduxjs/toolkit';

import type { RootState } from './index';

const API_HOST_URL = process.env.REACT_APP_API_ENDPOINT;

const DEFAULT_METHOD = 'GET';
const DEFAULT_HEADERS = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

interface FetchApiOptions extends RequestInit {
  /**
   * Whether or not the route requires authentication, defaults to false.
   * */
  useAuth?: boolean;
}

interface BoomResponse {
  payload: {
    statusCode: number;
    error: string;
    message: string;
  };
}

type FetchApiThunk<T> = ThunkAction<
  Promise<T>,
  RootState,
  unknown,
  Action<string>
>;

const fetchApiWithAuth = async (
  endpoint: string,
  options: FetchApiOptions,
  accessToken: string | undefined,
) => {
  const configuredFetch = (configuredFetchAccessToken: string) => {
    const authHeader = {
      Authorization: `Bearer ${configuredFetchAccessToken}`,
    };
    const headers = {
      ...DEFAULT_HEADERS,
      ...options.headers,
      ...authHeader,
    } as Record<string, string>;

    return fetch(`${API_HOST_URL}${endpoint}`, {
      method: DEFAULT_METHOD,
      ...options,
      headers,
    });
  };
  if (!accessToken) {
    throw new Error('Attempted to request a private resource with no token');
  }
  return configuredFetch(accessToken);
};

export const fetchApi = <T>(
  endpoint: string,
  options: FetchApiOptions = {},
): FetchApiThunk<T> => async (dispatch, getState) => {
  try {
    const useAuth = options.useAuth ?? false;
    let response: Response;
    if (useAuth) {
      const { accessToken } = getState().user;
      response = await fetchApiWithAuth(endpoint, options, accessToken);
    } else {
      const headers = {
        ...DEFAULT_HEADERS,
        ...options.headers,
      } as Record<string, string>;

      response = await fetch(`${API_HOST_URL}${endpoint}`, {
        method: DEFAULT_METHOD,
        ...options,
        headers,
      });
    }
    const body: T | BoomResponse = await response.json();
    if (response.status >= 400) {
      // @TODO: This is only for development purposes and should be removed at some point
      // eslint-disable-next-line no-console
      console.error(body);
      throw new Error((body as BoomResponse).payload.message);
    }
    return body as T;
  } catch (err) {
    // @TODO: This is only for development purposes and should be removed at some point
    // eslint-disable-next-line no-console
    console.error(err);
    throw new Error('Request Failed');
  }
};
