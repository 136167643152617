interface ButtonProps {
  text: string;
  onClick: () => void;
  isSubmit?: boolean;
  className?: string;
}

export const Button: React.FC<ButtonProps> = ({
  text,
  onClick,
  isSubmit,
  className,
}) => {
  return (
    <button
      type={isSubmit ? 'submit' : 'button'}
      onClick={onClick}
      className={className}
    >
      {text}
    </button>
  );
};
