import assert from 'assert';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';

import { Entry } from 'common/types/Entry';
import { UserWord } from 'common/types/UserWord';
import { lookup } from 'store/api/lookup';

import { EntryCard } from './EntryCard';
import './styles.scss';

export const DictionaryPage = () => {
  const { query } = useParams<{ query: string }>();

  const [entries, setEntries] = useState<Entry[]>([]);
  /** Map of entry id to array of userwords where each arr index maps to the equivalent indexed sense */
  const [userWordMap, setUserWordMap] = useState<Map<number, UserWord[]>>();
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      const result: any = await dispatch(lookup(query));
      setEntries(result.payload.entries);
      const map = new Map();
      result.payload.userWords.forEach((uw: UserWord) => {
        if (map.has(uw.entry_id)) {
          map.get(uw.entry_id)[uw.sense_index] = uw;
        } else {
          const newArr = [];
          newArr[uw.sense_index] = uw;
          map.set(uw.entry_id, newArr);
        }
      });
      // @todo: Storing user words like this means that the map is stale after one increment and the page
      // has to be refreshed.
      setUserWordMap(map);
      setIsLoading(false);
    };

    fetchData();
  }, [query, dispatch]);

  if (isLoading) {
    return (
      <div className="dictionary-page">
        <div>Fetching words...</div>
      </div>
    );
  }

  assert(userWordMap);

  return (
    <div className="dictionary-page">
      <ul>
        {entries.map((entry) => (
          <li key={entry.id} className="word">
            <EntryCard
              entry={entry}
              // @ts-ignore
              userWords={
                userWordMap.has(entry.id) ? userWordMap.get(entry.id) : []
              }
            />
          </li>
        ))}
      </ul>
    </div>
  );
};
