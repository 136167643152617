import React from 'react';

import { AppBar } from './AppBar';

import './styles.scss';

const Shell: React.FC = ({ children }) => {
  return (
    <>
      <AppBar />
      <main className="main-container">{children}</main>
    </>
  );
};

export default Shell;
