import { store } from 'store';
import type { User } from 'store/slices/User/types';

import { getAccessToken, getUser } from './accessors';
import { stateLoaded } from './actions';

export interface LoadStatePayload {
  accessToken: string;
  user: User;
}

export const loadState = () => {
  const accessToken = getAccessToken();
  if (!accessToken) return;
  store.dispatch(
    stateLoaded({
      accessToken,
      user: getUser(),
    }),
  );
};
