import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Card } from 'common/types/Card';
import { ExampleSentence } from 'common/types/ExampleSentence';
import { UpcomingWord } from 'common/types/UpcomingWord';
import { doCard } from 'store/api/doCard';
import { doUpcomingCard } from 'store/api/doUpcomingCard';
import { loadCardSession } from 'store/api/loadCardSession';

import { FlashCard } from './FlashCard';
import './styles.scss';

const MAX_CORRECT_GRADE = 4;

interface CardContentId {
  entryId: number;
  senseIndex: number;
  sentence: ExampleSentence;
}

export const CardSessionPage = () => {
  const [loading, setLoading] = useState(true);
  const [upcoming, setUpcoming] = useState<UpcomingWord[]>([]);
  const [cards, setCards] = useState<Card[]>([]);
  const [retries, setRetries] = useState<CardContentId[]>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const loadCards = async () => {
      const res = await dispatch(loadCardSession());
      setLoading(false);
      // @ts-ignore
      setUpcoming(res.payload.upcomingWords);
      // @ts-ignore
      setCards(res.payload.cards);
    };

    loadCards();
  }, [dispatch]);

  if (loading) {
    return (
      <div className="card-session-page">
        <div>Loading...</div>
      </div>
    );
  }

  let currentCard: CardContentId;
  let isUpcoming = false;
  let isCard = false;
  if (upcoming.length > 0) {
    currentCard = {
      entryId: upcoming[0].entry_id,
      senseIndex: upcoming[0].sense_index,
      sentence: upcoming[0].sentence_data,
    };
    isUpcoming = true;
  } else if (cards.length > 0) {
    currentCard = {
      entryId: cards[0].entry_id,
      senseIndex: cards[0].sense_index,
      sentence: cards[0].sentence_data,
    };
    isCard = true;
  } else if (retries.length > 0) {
    // eslint-disable-next-line prefer-destructuring
    currentCard = retries[0];
  }

  // @ts-ignore
  if (!currentCard) {
    return (
      <div className="card-session-page">
        <div>No cards left!</div>
      </div>
    );
  }
  return (
    <div className="card-session-page">
      <FlashCard
        entryId={currentCard.entryId}
        senseIndex={currentCard.senseIndex}
        sentence={currentCard.sentence}
        onSubmit={async (grade) => {
          if (isUpcoming) {
            await dispatch(
              doUpcomingCard({
                entryId: currentCard.entryId,
                senseIndex: currentCard.senseIndex,
                grade,
              }),
            );
            upcoming.shift();
            setUpcoming([...upcoming]);
            if (grade < MAX_CORRECT_GRADE) {
              retries.push(currentCard);
              setRetries([...retries]);
            }
          } else if (isCard) {
            await dispatch(
              doCard({
                cardId: cards[0].id,
                grade,
              }),
            );
            cards.shift();
            setCards([...cards]);
            if (grade < MAX_CORRECT_GRADE) {
              retries.push(currentCard);
              setRetries([...retries]);
            }
          } else {
            retries.shift();
            if (grade < MAX_CORRECT_GRADE) {
              retries.push(currentCard);
            }
            setRetries([...retries]);
          }
        }}
      />
    </div>
  );
};
