import { createAsyncThunk } from '@reduxjs/toolkit';

import { Entry } from 'common/types/Entry';
import { AsyncThunkApi } from 'store';
import { fetchApi } from 'store/fetchApi';

export const fetchEntry = createAsyncThunk<Entry, number, AsyncThunkApi>(
  'fetchEntry',
  async (id, thunkApi) => {
    return thunkApi.dispatch(
      fetchApi<Entry>(`/entry/${id}`, {
        method: 'GET',
        useAuth: true,
      }),
    );
  },
);
