import assert from 'assert';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Button } from 'common/components/Button';
import { Entry } from 'common/types/Entry';
import { ExampleSentence } from 'common/types/ExampleSentence';
import { fetchEntry } from 'store/api/fetchEntry';

interface FlashCardProps {
  entryId: number;
  senseIndex: number;
  onSubmit: (grade: number) => void;
  sentence: ExampleSentence;
}
export const FlashCard: React.FC<FlashCardProps> = ({
  entryId,
  senseIndex,
  onSubmit,
  sentence,
}) => {
  const [loaded, setLoaded] = useState(false);
  const [flipped, setFlipped] = useState(false);
  const [entry, setEntry] = useState<Entry>();
  const dispatch = useDispatch();

  useEffect(() => {
    const loadContent = async () => {
      const res = await dispatch(fetchEntry(entryId));
      // @ts-ignore
      setEntry(res.payload);
      setLoaded(true);
    };
    loadContent();
  }, [entryId, senseIndex, dispatch]);

  if (!loaded) {
    return null;
  }

  const prefix = sentence.text.substring(0, sentence.wordStartIndex);
  const surfaceForm = sentence.text.substring(
    sentence.wordStartIndex,
    sentence.wordEndIndex,
  );
  const postfix = sentence.text.substring(sentence.wordEndIndex);

  assert(entry);

  if (flipped) {
    return (
      <div className="flashcard">
        <p>{entry.data.senses[senseIndex].glosses[0]}</p>
        <div className="response-container">
          <Button
            text="0"
            onClick={() => {
              onSubmit(0);
              setFlipped(false);
              setEntry(undefined);
              setLoaded(false);
            }}
          />
          <Button
            text="1"
            onClick={() => {
              onSubmit(1);
              setFlipped(false);
              setEntry(undefined);
              setLoaded(false);
            }}
          />
          <Button
            text="2"
            onClick={() => {
              onSubmit(2);
              setFlipped(false);
              setEntry(undefined);
              setLoaded(false);
            }}
          />
          <Button
            text="3"
            onClick={() => {
              onSubmit(3);
              setFlipped(false);
              setEntry(undefined);
              setLoaded(false);
            }}
          />
          <Button
            text="4"
            onClick={() => {
              onSubmit(4);
              setFlipped(false);
              setEntry(undefined);
              setLoaded(false);
            }}
          />
          <Button
            text="5"
            onClick={() => {
              onSubmit(5);
              setFlipped(false);
              setEntry(undefined);
              setLoaded(false);
            }}
          />
        </div>
      </div>
    );
  }
  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className="flashcard"
      onClick={() => setFlipped(true)}
      onKeyDown={(e) => e.key === 'Enter' && setFlipped(true)}
    >
      <div className="sentence-container">
        <p className="sentence">{prefix}</p>
        <b className="surface-form">{surfaceForm}</b>
        <p className="sentence">{postfix}</p>
      </div>
    </div>
  );
};
