import type { User } from '../slices/User/types';

const ACCESS_TOKEN_KEY = 'access_token';

export const saveAccessToken = (token: string) =>
  localStorage.setItem(ACCESS_TOKEN_KEY, token);
export const removeAccessToken = () =>
  localStorage.removeItem(ACCESS_TOKEN_KEY);
export const getAccessToken = () => localStorage.getItem(ACCESS_TOKEN_KEY);

const USER_KEY = 'user';

export const saveUser = (user: User) =>
  localStorage.setItem(USER_KEY, JSON.stringify(user));
export const removeUser = () => localStorage.removeItem(USER_KEY);
export const getUser = () => {
  const user = localStorage.getItem(USER_KEY);
  if (!user) return undefined;
  return JSON.parse(user);
};
