import { createAsyncThunk } from '@reduxjs/toolkit';

import { AsyncThunkApi } from 'store';
import { fetchApi } from 'store/fetchApi';

interface DoUpcomingCardParams {
  grade: number;
  entryId: number;
  senseIndex: number;
}
export const doUpcomingCard = createAsyncThunk<
  void,
  DoUpcomingCardParams,
  AsyncThunkApi
>('doUpcomingCard', async (params, thunkApi) => {
  return thunkApi.dispatch(
    fetchApi<void>('/card/do-upcoming/', {
      method: 'POST',
      body: JSON.stringify({
        grade: params.grade,
        entryId: params.entryId,
        senseIndex: params.senseIndex,
      }),
      useAuth: true,
    }),
  );
});
