import { createAsyncThunk } from '@reduxjs/toolkit';

import { Entry } from 'common/types/Entry';
import { UserWord } from 'common/types/UserWord';
import { AsyncThunkApi } from 'store';
import { fetchApi } from 'store/fetchApi';

interface LookupResponse {
  entries: Entry[];
  userWords: UserWord[];
}

export const lookup = createAsyncThunk<LookupResponse, string, AsyncThunkApi>(
  'lookup',
  async (query, thunkApi) => {
    return thunkApi.dispatch(
      fetchApi<LookupResponse>(`/entry/lookup/${query}`, {
        method: 'GET',
        useAuth: true,
      }),
    );
  },
);
