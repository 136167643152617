import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';

import { Button } from 'common/components/Button';
import { selectUser } from 'store/slices/User/selectors';
import { logout } from 'store/slices/User/slice';

import './styles.scss';

interface AppBarProps {}

const useOnRoutes = ['/', '/dict'];

export const AppBar: React.FC<AppBarProps> = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);

  if (
    useOnRoutes.includes(location.pathname) ||
    location.pathname.slice(0, 5) === '/dict'
  ) {
    return (
      <div className="app-bar">
        <div className="left">
          <Link to="/">
            <h1>Kioku</h1>
          </Link>
        </div>
        <div className="right">
          <p className="greeting">Hello, {user ? user.email : ''}</p>
          <Button text="Log out" onClick={() => dispatch(logout())} />
        </div>
      </div>
    );
  }
  return <div />;
};
