import { createAsyncThunk } from '@reduxjs/toolkit';

import type { AsyncThunkApi } from 'store';
import { saveAccessToken, saveUser } from 'store/storage/accessors';

import { fetchApi } from '../../fetchApi';
import { User } from './types';

interface AuthPayload {
  user: User;
  accessToken: string;
}
export const signUp = createAsyncThunk<
  AuthPayload,
  { email: string; password: string },
  AsyncThunkApi
>('user/signUp', async (args, thunkApi) => {
  const payload = await thunkApi.dispatch(
    fetchApi<AuthPayload>('/auth/signup', {
      method: 'POST',
      body: JSON.stringify(args),
    }),
  );
  saveUser(payload.user);
  saveAccessToken(payload.accessToken);
  return payload;
});

export const login = createAsyncThunk<
  AuthPayload,
  { email: string; password: string },
  AsyncThunkApi
>('user/login', async (args, thunkApi) => {
  const payload = await thunkApi.dispatch(
    fetchApi<AuthPayload>('/auth/login', {
      method: 'POST',
      body: JSON.stringify(args),
    }),
  );
  saveUser(payload.user);
  saveAccessToken(payload.accessToken);
  return payload;
});
