import { createAsyncThunk } from '@reduxjs/toolkit';

import { AsyncThunkApi } from 'store';
import { fetchApi } from 'store/fetchApi';

interface DoCardParams {
  grade: number;
  cardId: string;
}
export const doCard = createAsyncThunk<void, DoCardParams, AsyncThunkApi>(
  'doCard',
  async (params, thunkApi) => {
    return thunkApi.dispatch(
      fetchApi<void>(`/card/do/${params.cardId}`, {
        method: 'POST',
        body: JSON.stringify({
          grade: params.grade,
        }),
        useAuth: true,
      }),
    );
  },
);
