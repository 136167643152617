import { createAsyncThunk } from '@reduxjs/toolkit';

import { Sentence } from 'common/types/Sentence';
import { AsyncThunkApi } from 'store';
import { fetchApi } from 'store/fetchApi';

export const fetchSentences = createAsyncThunk<
  Sentence[],
  string,
  AsyncThunkApi
>('fetchSentences', async (query, thunkApi) => {
  const params = new URLSearchParams({
    text: query,
  });
  return thunkApi.dispatch(
    fetchApi<Sentence[]>(`/sentence/?${params.toString()}`, {
      method: 'GET',
    }),
  );
});
