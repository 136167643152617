/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, RouteProps, Switch } from 'react-router-dom';

import { AuthPage } from 'pages/Auth';
import { CardSessionPage } from 'pages/CardSession';
import { DictionaryPage } from 'pages/Dictionary';
import { HomePage } from 'pages/Home';
import { selectIsLoggedIn } from 'store/slices/User/selectors';

const AuthorizedRoute: React.FC<RouteProps> = ({ children, ...rest }) => {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  return (
    <Route {...rest}>{isLoggedIn ? children : <Redirect to="/auth" />}</Route>
  );
};

const RootRoutes = () => (
  <Switch>
    <Route exact path="/auth">
      <AuthPage />
    </Route>
    <AuthorizedRoute exact path="/">
      <HomePage />
    </AuthorizedRoute>
    <AuthorizedRoute exact path="/dict/:query">
      <DictionaryPage />
    </AuthorizedRoute>
    <AuthorizedRoute exact path="/cards">
      <CardSessionPage />
    </AuthorizedRoute>
    <Route>
      <h1>Not Found</h1>
    </Route>
  </Switch>
);

export default RootRoutes;
