import {
  combineReducers,
  configureStore,
  Action,
  ThunkAction,
} from '@reduxjs/toolkit';

import { userSlice } from './slices/User/slice';

const rootReducer = combineReducers({
  user: userSlice.reducer,
});
export type RootState = ReturnType<typeof rootReducer>;

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ immutableCheck: false }),
});
export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;

// Use with `createAsyncThunk` to define the type of the `thunkApi` arg
export interface AsyncThunkApi {
  dispatch: typeof store.dispatch;
  state: RootState;
}
