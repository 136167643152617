import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Button } from 'common/components/Button';
import { Entry } from 'common/types/Entry';
import { Sentence } from 'common/types/Sentence';
import { UserWord } from 'common/types/UserWord';
import { fetchSentences } from 'store/api/fetchSentences';
import { increment } from 'store/api/increment';
import './entryStyles.scss';

interface EntryCardProps {
  entry: Entry;
  userWords: UserWord[];
}

export const EntryCard: React.FC<EntryCardProps> = ({ entry, userWords }) => {
  const dispatch = useDispatch();
  const [sentence, setSentence] = useState('');
  const [hasIncremented, setHasIncremented] = useState(false);
  const [sentences, setSentences] = useState<Sentence[]>([]);

  return (
    <div className="entry">
      <div className="kanji">
        {entry.data.kanji
          ? entry.data.kanji.map((k, i) => (
              // eslint-disable-next-line react/jsx-indent
              <span className={i === 0 ? 'main' : ''} key={k.symbol}>
                {`${k.symbol} `}
              </span>
            ))
          : null}
      </div>
      <div className="kanji">
        {entry.data.readings
          ? entry.data.readings.map((r) => (
              // eslint-disable-next-line react/jsx-indent
              <span key={r.symbol}>{`${r.symbol} `}</span>
            ))
          : null}
      </div>
      <div className="sense">
        <ol>
          {entry.data.senses.map((s, senseIndex) => (
            <div key={s.glosses[0]} className="sense-container">
              <div className="dictionary-data">
                {s.pos ? (
                  <span className="pos">
                    {s.pos instanceof Array
                      ? s.pos.map((str, i) => `${i === 0 ? '' : '; '}${str}`)
                      : s.pos}
                  </span>
                ) : null}
                <li>{s.glosses.map((g, i) => `${i === 0 ? '' : '; '}${g}`)}</li>
              </div>
              {!hasIncremented && (
                <div className="actions">
                  <input
                    className="sentence-input"
                    value={sentence}
                    onChange={(e) => setSentence(e.target.value)}
                  />
                  <Button
                    onClick={() => {
                      dispatch(
                        increment({
                          entryId: entry.id,
                          senseIndex,
                          userWordId: userWords[senseIndex]?.id,
                          sentence: sentence === '' ? undefined : sentence,
                        }),
                      );
                      setHasIncremented(true);
                    }}
                    text="inc"
                  />
                </div>
              )}
            </div>
          ))}
        </ol>
      </div>
      {sentences.length > 0 && (
        <div className="sentences-container">
          {sentences.map((s) => (
            <div className="sentence">
              <p className="jp">{s.text}</p>
              <p className="translation">{s.translation}</p>
            </div>
          ))}
        </div>
      )}
      <div className="footer">
        <div className="data">
          <p>{entry.id}</p>
          <p>JLPT: {`n${entry.jlpt ? entry.jlpt : 0}`}</p>
        </div>

        <div className="actions">
          <Button
            text="sentences"
            onClick={async () => {
              const query = entry.data.kanji
                ? entry.data.kanji[0].symbol
                : entry.data.readings[0].symbol;
              const res = await dispatch(fetchSentences(query));
              // @ts-ignore
              setSentences(res.payload);
            }}
          />
        </div>
      </div>
    </div>
  );
};
