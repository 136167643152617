import { createSlice } from '@reduxjs/toolkit';

import { removeAccessToken, removeUser } from 'store/storage/accessors';
import { stateLoaded } from 'store/storage/actions';

import { login, signUp } from './api';
import { User } from './types';

interface UserSliceInitialState {
  user?: User;
  fetching: boolean;
  error?: string;
  accessToken?: string;
}

const INITIAL_STATE: UserSliceInitialState = {
  fetching: false,
};

export const userSlice = createSlice({
  name: 'User',
  initialState: INITIAL_STATE,
  reducers: {
    logout: (state) => {
      removeUser();
      removeAccessToken();
      state.user = undefined;
      state.accessToken = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signUp.fulfilled, (state, action) => {
      state.user = action.payload.user;
      state.accessToken = action.payload.accessToken;
      state.fetching = false;
    });
    builder.addCase(signUp.pending, (state) => {
      state.fetching = true;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      state.user = action.payload.user;
      state.accessToken = action.payload.accessToken;
      state.fetching = false;
    });
    builder.addCase(login.pending, (state) => {
      state.fetching = true;
    });
    builder.addCase(stateLoaded, (state, action) => {
      state.user = action.payload.user;
      state.accessToken = action.payload.accessToken;
    });
  },
});

export const { logout } = userSlice.actions;
