import { createAsyncThunk } from '@reduxjs/toolkit';

import { Card } from 'common/types/Card';
import { UpcomingWord } from 'common/types/UpcomingWord';
import { AsyncThunkApi } from 'store';
import { fetchApi } from 'store/fetchApi';

interface LoadCardSessionPayload {
  upcomingWords: UpcomingWord[];
  cards: Card[];
}

export const loadCardSession = createAsyncThunk<
  LoadCardSessionPayload,
  void,
  AsyncThunkApi
>('loadCardSession', async (_, thunkApi) => {
  return thunkApi.dispatch(
    fetchApi<LoadCardSessionPayload>('/card/session', {
      method: 'GET',
      useAuth: true,
    }),
  );
});
